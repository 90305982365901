import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let MyVillageIcon = class MyVillageIcon extends Vue {
    constructor() {
        super(...arguments);
        this.notificationCount = '';
    }
    async mounted() {
        await this.setCount();
    }
    setCount() {
        return this.notificationCount = this.count >= 100 ? '99+' : this.count;
    }
};
__decorate([
    Prop()
], MyVillageIcon.prototype, "icon", void 0);
__decorate([
    Prop()
], MyVillageIcon.prototype, "label", void 0);
__decorate([
    Prop()
], MyVillageIcon.prototype, "count", void 0);
__decorate([
    Prop()
], MyVillageIcon.prototype, "routeTo", void 0);
MyVillageIcon = __decorate([
    Component
], MyVillageIcon);
export default MyVillageIcon;
