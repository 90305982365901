import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import Sidebar from '@/components/Elements/Sidebar.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import Btn from '@/components/Button/Btn.vue';
import MyVillageIcon from '@/views/MyVillage/Components/MyVillageIcon.vue';
// @ts-ignore
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
let MyVillage = class MyVillage extends Vue {
    constructor() {
        super(...arguments);
        this.carouselData = {};
        this.hooperSettings = {
            itemsToSlide: 1,
            centerMode: true,
            pagination: false,
            infiniteScroll: true,
            autoPlay: false,
            wheelControl: false,
            breakpoints: {
                1280: {
                    itemsToShow: 2,
                },
                768: {
                    itemsToShow: 1.5,
                },
                0: {
                    itemsToShow: 1,
                },
            },
        };
    }
    onChange() {
        // allow carouselData to be used to style the center slide
        // set carsouselData to last slide
        if (this.carouselData == -1) {
            this.carouselData = 5;
            // @ts-ignore
        }
        else if (this.carouselData >= this.$refs.carousel.slidesCount) {
            this.carouselData = 0;
        }
        // @ts-ignore
        this.$refs.carousel.slideTo(this.carouselData);
    }
    beforeEnter() {
        if (this.onboarding) {
            this.$router.push('onboarding');
        }
    }
    async mounted() {
        if (this.$store.getters['auth/loggedIn']) {
            await this.$store.dispatch('user/getUserProfile');
        }
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: '',
            engagementSlug: '',
        });
        // Subscript to notifications
        await this.$store.dispatch('push_notifications/subscribe');
    }
    slidePrev() {
        // @ts-ignore
        this.$refs.carousel.slidePrev();
    }
    slideNext() {
        // @ts-ignore
        this.$refs.carousel.slideNext();
    }
    updateCarousel(payload) {
        this.carouselData = payload.currentSlide;
    }
};
__decorate([
    Getter('onboarding', { namespace: 'user' })
], MyVillage.prototype, "onboarding", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], MyVillage.prototype, "profile", void 0);
__decorate([
    Getter('getResourceCount', { namespace: 'user' })
], MyVillage.prototype, "resourceCount", void 0);
__decorate([
    Watch('carouselData')
], MyVillage.prototype, "onChange", null);
MyVillage = __decorate([
    Component({
        components: {
            Hooper,
            Slide,
            Btn,
            ProfileCard,
            Sidebar,
            CoverImage,
            MyVillageIcon,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | My Village',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic My Village',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/my-village` }],
            };
        },
    })
], MyVillage);
export default MyVillage;
