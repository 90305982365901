import { __decorate } from "tslib";
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import LabelPill from '@/components/Label/LabelPill/LabelPill.vue';
let ProfileCard = class ProfileCard extends Vue {
    constructor() {
        super(...arguments);
        this.userName = '';
        this.isBlocked = false;
        this.isBlockedLabel = {
            id: 'blocked',
            name: 'Blocked',
            description: 'Blocked',
            color: '#DD4B39',
            rgb_color: '237, 150, 135',
            created_at: new Date(),
            updated_at: new Date(),
        };
    }
    mounted() {
        if (this.profile) {
            this.userName = this.profile.known_as ? this.profile.known_as : this.profile.name;
        }
        if (this.$store.getters["auth/loggedIn"]) {
            this.setBlocked();
        }
    }
    onUserChange() {
        if (this.profile) {
            this.userName = this.profile.known_as ? this.profile.known_as : this.profile.name;
        }
    }
    setBlocked() {
        let blockedMembers = [];
        if (this.currentUserProfile.blocked_members && this.currentUserProfile.blocked_members.length > 0) {
            blockedMembers = this.currentUserProfile.blocked_members.filter((blockedMember) => {
                return blockedMember.blocked_member_id === this.profile.id;
            });
        }
        if (blockedMembers.length > 0) {
            this.isBlocked = true;
        }
        else {
            this.isBlocked = false;
        }
    }
    async goToProfile() {
        if (this.linkable) {
            if (this.currentUserProfile.id === this.profile.id) {
                this.$router.push({ path: `/profile` });
            }
            else {
                await this.$store.dispatch('members/setActiveMember', this.profile);
                this.$router.push({ path: `/member/${this.profile.id}` });
            }
        }
    }
};
__decorate([
    Prop()
], ProfileCard.prototype, "profile", void 0);
__decorate([
    Prop({ default: 'medium' })
], ProfileCard.prototype, "size", void 0);
__decorate([
    Prop({ default: 'horizontal' })
], ProfileCard.prototype, "orientation", void 0);
__decorate([
    Prop({ default: true })
], ProfileCard.prototype, "showAvatar", void 0);
__decorate([
    Prop({ default: true })
], ProfileCard.prototype, "linkable", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], ProfileCard.prototype, "currentUserProfile", void 0);
__decorate([
    Watch('profile')
], ProfileCard.prototype, "onUserChange", null);
ProfileCard = __decorate([
    Component({
        components: { LabelPill },
    })
], ProfileCard);
export default ProfileCard;
