import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let CoverImage = class CoverImage extends Vue {
    constructor() {
        super(...arguments);
        this.fixedClasses = '';
    }
    mounted() {
        const xsFixed = this.fixed.includes('xs');
        const mdFixed = this.fixed.includes('md');
        // if fixed on mobile and not desktop
        if (xsFixed && !mdFixed) {
            this.fixedClasses = 'fixed top-0 md:relative';
            // fixed on desktop and not mobile
        }
        else if (!xsFixed && mdFixed) {
            this.fixedClasses = 'relative md:fixed md:top-0';
            // fixed on desktop and mobile
        }
        else if (xsFixed && mdFixed) {
            this.fixedClasses = 'fixed top-0';
        }
        else {
            // relative coverimage
            this.fixedClasses = 'relative';
        }
    }
};
__decorate([
    Prop({ default: '/img/bg/lodges-bg.jpg' })
], CoverImage.prototype, "image", void 0);
__decorate([
    Prop({ default: [] })
], CoverImage.prototype, "fixed", void 0);
__decorate([
    Prop({ default: '' })
], CoverImage.prototype, "caption", void 0);
__decorate([
    Prop({ default: '' })
], CoverImage.prototype, "title", void 0);
__decorate([
    Prop({ default: '' })
], CoverImage.prototype, "description", void 0);
__decorate([
    Prop({ default: false })
], CoverImage.prototype, "tall", void 0);
__decorate([
    Prop({ default: false })
], CoverImage.prototype, "offerBadge", void 0);
CoverImage = __decorate([
    Component
], CoverImage);
export default CoverImage;
