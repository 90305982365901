var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.profile)?_c('div',{class:{ 'cursor-pointer': _vm.linkable },attrs:{"data-component":"profile-card"},on:{"click":function($event){return _vm.goToProfile()}}},[_c('div',{staticClass:"flex items-center"},[(_vm.showAvatar && _vm.profile && _vm.profile.avatar && _vm.profile.avatar.public_url)?_c('img',{staticClass:"rounded-full flex-shrink-0 font-light",class:[
                { 'h-6 w-6 mr-1': _vm.size === 'small' },
                { 'h-8 w-8 mr-2': _vm.size === 'medium' },
                { 'h-10 w-10 mr-2': _vm.size === 'large' } ],attrs:{"src":_vm.profile.avatar.public_url,"alt":"","width":"30","height":"30"}}):_vm._e(),(_vm.profile.name && _vm.showAvatar && !_vm.profile.avatar)?_c('div',{staticClass:"\n                rounded-full\n                flex-shrink-0\n                font-light\n                bg-black\n                tracking-wide\n                text-xs text-white\n                flex\n                items-center\n                justify-center\n            ",class:[
                { 'h-6 w-6 mr-1': _vm.size === 'small' },
                { 'h-8 w-8 mr-2': _vm.size === 'medium' },
                { 'h-10 w-10 mr-2': _vm.size === 'large' } ]},[_vm._v(" "+_vm._s(_vm.profile.name.substring(0, 1))+" ")]):_vm._e(),(_vm.userName)?_c('div',{staticClass:"flex flex-wrap",class:[
                { 'flex-col items-start': _vm.orientation === 'vertical' },
                { 'items-start flex-row md:items-center md:leading-none': _vm.orientation === 'horizontal' } ]},[_c('div',{staticClass:"mr-2 font-bold md:mr-4",class:[
                    { 'text-xxs md:text-xs': _vm.size === 'small' },
                    { 'text-sm': _vm.size === 'medium' },
                    { 'text-xl': _vm.size === 'large' } ]},[_vm._v(" "+_vm._s(_vm.userName)+" ")]),(_vm.profile && _vm.profile.roles.length > 0 && !_vm.isBlocked)?_c('label-pill',{staticClass:"my-1",attrs:{"size":_vm.size,"label":_vm.profile.roles[0]}}):_vm._e(),(_vm.isBlocked)?_c('label-pill',{staticClass:"my-1",attrs:{"size":_vm.size,"label":_vm.isBlockedLabel}}):_vm._e()],1):_vm._e()]),(_vm.profile.country && ['member'].includes(_vm.$route.name))?_c('p',{staticClass:"text-ag-brown-grey text-xs mt-2 mb-2"},[_vm._v(" "+_vm._s(_vm.profile.country.name)+" ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }