import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Sidebar = class Sidebar extends Vue {
    constructor() {
        super(...arguments);
        this.fixedPosition = false;
        this.screenWidth = 0;
    }
    mounted() {
        this.handleWidthChange();
    }
    // When the component is created track the scroll position
    created() {
        if (this.sticky) {
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.handleWidthChange);
        }
    }
    // When the component is destroyed stop tracking to ensure lower memory use
    destroyed() {
        if (this.sticky) {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.handleWidthChange);
        }
    }
    // Update the scroll pos once scrolling
    handleScroll() {
        if (this.sticky && window.innerWidth > 768) {
            this.fixedPosition = window.scrollY > this.offset;
        }
        else {
            this.fixedPosition = false;
        }
        this.$emit('isFixed', this.fixedPosition);
    }
    // Handle the width changing so we can update sidebar widths
    handleWidthChange() {
        this.screenWidth = window.innerWidth;
    }
    get styleObject() {
        return {
            width: (this.screenWidth > 768 && this.fixedPosition) ? this.stickyWidth + 'px' : 'auto',
            top: (this.screenWidth > 768 && this.fixedPosition) ? this.stickyTop + 'px' : '0'
        };
    }
};
__decorate([
    Prop({ default: false })
], Sidebar.prototype, "sticky", void 0);
__decorate([
    Prop({ default: 0 })
], Sidebar.prototype, "offset", void 0);
__decorate([
    Prop({ default: 300 })
], Sidebar.prototype, "stickyWidth", void 0);
__decorate([
    Prop({ default: 0 })
], Sidebar.prototype, "stickyTop", void 0);
Sidebar = __decorate([
    Component
], Sidebar);
export default Sidebar;
