import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let LabelPill = class LabelPill extends Vue {
    async click() {
        if (!this.$store.getters['auth/loggedIn']) {
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        this.$emit('label-clicked', this.label);
        if (this.$route.name == 'stories' || this.$route.name == 'story') {
            this.$router.push({ path: `/stories/topic-search?label=${this.label.id}` });
        }
        else if (this.$route.name == 'discussions' || this.$route.name == 'discussion') {
            this.$router.push({ path: `/discussions/topic-search?label=${this.label.id}` });
        }
        else if (this.$route.name == 'projects' || this.$route.name == 'project') {
            this.$router.push({ path: `/projects/topic-search?label=${this.label.id}` });
        }
    }
};
__decorate([
    Prop()
], LabelPill.prototype, "label", void 0);
__decorate([
    Prop({ default: true })
], LabelPill.prototype, "primary", void 0);
__decorate([
    Prop({ default: 'small' })
], LabelPill.prototype, "size", void 0);
__decorate([
    Prop({ default: false })
], LabelPill.prototype, "isClickable", void 0);
LabelPill = __decorate([
    Component
], LabelPill);
export default LabelPill;
